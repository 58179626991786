import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import cx from "classnames";
import BaseMoneyInput from "../../components/Inputs/BaseMoneyInput";
import Switch from "../../components/Switch";

import { useCommonStyles } from "../../../../utils/loan-application.styles";
import { moneyTypeOptions } from "../../../../commons/loan-application-constants";

const useStyles = makeStyles((theme) => ({
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "8px",
    },
  },
  fullWidthOnMobile: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const ExpenseItem = ({ itemData, formik, index }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <Box display="flex" flexDirection="column" gridRowGap="32px">
      <Box
        display="flex"
        flexDirection="column"
        gridRowGap="24px"
        minHeight="96px"
      >
        <Box classes={{ root: classes.topContainer }}>
          <Typography
            classes={{
              root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
            }}
          >
            {itemData.label}
          </Typography>
          <Box classes={{ root: classes.fullWidthOnMobile }}>
            <Switch
              showValue
              label="Expenses share equally"
              labelPlacement="start"
              value={formik.values.expenses[index].isEquallyShared}
              onChange={(value) =>
                formik.setFieldValue(
                  `expenses[${index}].isEquallyShared`,
                  value
                )
              }
            />
          </Box>
        </Box>
        <Typography
          classes={{
            root: cx(
              commonClasses.fontSize12,
              commonClasses.fontWeightLight,
              commonClasses.textGrey
            ),
          }}
        >
          {itemData.description}
        </Typography>
      </Box>
      <BaseMoneyInput
        options={moneyTypeOptions}
        minValue={0}
        selectedOption={formik.values.expenses[index].frequency}
        setSelectedOption={(value) =>
          formik.setFieldValue(`expenses[${index}].frequency`, value)
        }
        onChange={(value) =>
          formik.setFieldValue(`expenses[${index}].amount`, value)
        }
        value={formik.values.expenses[index].amount}
        maxValue={1000}
      />
    </Box>
  );
};

export default ExpenseItem;
