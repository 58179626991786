import React, { useEffect, useState } from "react";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NumberFormat from "react-number-format";
import cx from "classnames";

import {
  useCommonStyles,
  useStyles as useSectionStyles,
} from "../../../utils/loan-application.styles";
import ExpenseItem from "./components/ExpenseItem";
import Button from "../components/Button";
import {
  dependantExpensesType,
  expensesType,
} from "../../../commons/loan-application-constants";
import { useNotification } from "../../../hooks/loan-application.hooks";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "48px",
    },
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "16px",
    },
  },
  noMarginOnMobile: {
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
}));

const Expenses = ({ formik, onFinishStep, tabKey }) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();
  const commonClasses = useCommonStyles();
  const { showNotification } = useNotification();

  const renderedSteps = formik.values.dependants
    ? expensesType.concat(dependantExpensesType)
    : expensesType;

  useEffect(() => {
    if (formik.values.expenses.length !== renderedSteps.length) {
      renderedSteps.forEach((step, index) => {
        formik.setFieldValue(`expenses[${index}]`, {
          type: step.value,
          amount: 0,
          frequency: "monthly",
          isEquallyShared: false,
        });
      });
    }
  }, [renderedSteps, formik]);

  const [currentStep, setCurrentStep] = useState(renderedSteps[0]);
  const firstStep = renderedSteps[0];
  const lastStep = renderedSteps.at(-1);

  const totalExpenses =
    formik.values.expenses?.reduce((acc, expense) => {
      if (expense.frequency === "weekly") {
        return acc + expense.amount * 4;
      }
      return acc + expense.amount;
    }, 0) || 0;

  const handleNextStep = () => {
    const currentIndex = renderedSteps.findIndex(
      (step) => step.value === currentStep.value
    );
    if (currentIndex < renderedSteps.length - 1) {
      setCurrentStep(renderedSteps[currentIndex + 1]);
    }
  };

  const handlePrevStep = () => {
    const currentIndex = renderedSteps.findIndex(
      (step) => step.value === currentStep.value
    );
    if (currentIndex > 0) {
      setCurrentStep(renderedSteps[currentIndex - 1]);
    }
  };

  const handleSaveSection = async () => {
    const data = await formik.validateForm();
    if (data.expenses) {
      showNotification(
        "Please fill in all required fields of Expenses section",
        "error"
      );
      return;
    }
    onFinishStep(tabKey);
  };

  return (
    <Box classes={{ root: classes.container }}>
      <Box classes={{ root: classes.topContainer }}>
        <Typography
          classes={{
            root: cx(sectionClasses.stepTitle, classes.noMarginOnMobile),
          }}
        >
          Your Expenses
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          gridColumnGap="16px"
          paddingRight="24px"
        >
          <Typography
            classes={{
              root: cx(commonClasses.fontSize24, commonClasses.fontWeightBold),
            }}
          >
            Total:
          </Typography>
          <NumberFormat
            value={totalExpenses}
            displayType="text"
            thousandSeparator=","
            decimalScale={0}
            fixedDecimalScale={false}
            renderText={(value) => (
              <Typography
                classes={{
                  root: commonClasses.fontSize24,
                }}
              >
                ${value}
              </Typography>
            )}
          />
        </Box>
      </Box>
      <TabContext value={currentStep.value}>
        {renderedSteps.map((expenseType, index) => (
          <TabPanel
            key={expenseType.value}
            value={expenseType.value}
            classes={{
              root: sectionClasses.tabPanelRootMobileNoPadding,
            }}
          >
            <ExpenseItem itemData={expenseType} formik={formik} index={index} />
          </TabPanel>
        ))}
      </TabContext>
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        classes={{ root: sectionClasses.navigationButtonsContainer }}
      >
        <Button
          startIcon={<NavigateBeforeIcon />}
          onClick={handlePrevStep}
          hidden={currentStep.value === firstStep.value}
        >
          Previous
        </Button>
        {currentStep.value === lastStep.value ? (
          <Button endIcon={<NavigateNextIcon />} onClick={handleSaveSection}>
            Save and Next
          </Button>
        ) : (
          <>
            <Button endIcon={<NavigateNextIcon />} onClick={handleNextStep}>
              Next
            </Button>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Expenses;
